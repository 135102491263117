<template>
  <div class="cases_box main">
    <div class="container">
      <div class="detail_header">
        <div class="title">Cases</div>
        <div class="year_box">
          Awards:
          <el-select
            v-model="listQuery.awards"
            placeholder="Select"
            @change="_initList"
			clearable
          >
            <el-option
              v-for="(item, index) in awardsOptions"
              :key="index"
              :value="item"
              :label="item"
            >
              {{ item }}
            </el-option>
          </el-select>
        </div>
        <!-- <div class="year_box">
          Level:
          <el-select v-model="year" placeholder="Select">
            <el-option value="1" label="2021"></el-option>
            <el-option value="2" label="2022"></el-option>
          </el-select>
        </div> -->
        <div class="year_box">
          Category:
          <el-select
            v-model="listQuery.category"
            placeholder="Select"
            @change="_initList"
			clearable
          >
            <el-option
              v-for="(item, index) in categoryOptions"
              :key="index"
              :value="item"
              :label="item"
            >
              {{ item }}
            </el-option>
          </el-select>
        </div>
        <div class="year_box">
		  Year:
		  <el-select
		    v-model="listQuery.year"
		    placeholder="Select"
		    @change="_initList"
			clearable
		  >
		    <el-option
		      v-for="(item, index) in yearOptions"
		      :key="index"
		      :value="item"
		      :label="item"
		    >
		      {{ item }}
		    </el-option>
		  </el-select>
		</div>
      </div>
      <div class="enrty_list">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="toDetail(item.id)"
        >
          <img class="img" :src="item.cover_img" />
          <div class="name">
            {{ item.title }}
          </div>
        </div>
        <!-- <div class="item more" @click="load_more">
          <img class="img" src="../../assets/images/more.png" />
          <div class="name"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { CASESLIST, GETOPTIONS } from '@/api/awards';
export default {
  name: 'cases',
  components: {},
  data() {
    return {
      year: '',
      listQuery: {
        year: '',
        category: '',
        awards: '',
      },
      yearOptions: [],
      categoryOptions: [],
      awardsOptions: [],
      list: [],
    };
  },
  created() {
    this._initPage();
  },
  methods: {
    async _initPage() {
      await this._initOptions();
      await this._initList();
    },
    async _initOptions() {
      const { data } = await GETOPTIONS();
      console.log(data);
      const { awards, category, year } = data;
      if (awards) {
        this.awardsOptions = awards;
      }
      if (category) {
        this.categoryOptions = category;
      }
      if (year) {
        this.yearOptions = year;
      }
    },
    async _initList() {
      const { data } = await CASESLIST(this.listQuery);
      console.log(data);
      if (data) {
        this.list = data;
      }
    },
    toDetail(id) {
      this.$router.push({
        name: 'AwardsCasesDetail',
		params: {
			id
		}
      });
    },
    load_more() {
      console.log('loadmore');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/cases.scss';
</style>
